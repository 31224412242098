import DataTable from 'react-data-table-component';
import { useState } from 'react';
import OrderDetail from './orderDetail';
import formatDate from '../common/FormatDate';
import {HiOutlineDotsVertical} from 'react-icons/hi';

function CustomerTable(props) {
    const columns = [
        {
            name: 'User ID',
            selector: row => row._id
        },
        {
            name: 'Name',
            selector: row => row.first_name ?  row.first_name : "" + " " + row.last_name ? row.last_name:"",
            sortable: true
        },
        {
            name: 'Address',
            selector: row => row.address,
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true
        },
        {
            name: 'Contact Number',
            selector: row => row.phone_number,
            sortable: true
        },
        {
            name: 'Payment Method',
            selector: row => row.payment_method,
            sortable: true
        },
        {
            name: 'Member',
            selector: row => formatDate(row.created_date),
            sortable: true
        },
        {
            cell: row => <HiOutlineDotsVertical />,
            allowOverflow: true,
            button: true,
            width: '56px',
        },
    ];
    return (
        <>
            <DataTable
                columns={columns}
                data={props.CustomerData}
                selectableRows
                pagination
            />
        </>
    );
};


export default CustomerTable;